import React, { useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

import PrivateRoute from './PrivateRoute';
import { AuthContext } from './authContext';
import DefaultRoute from './DefaultRoute';
import image from '../assets/img/brand/logo.svg';

const loading = () => (
  <div className='view'>
    <div className='preloader d-flex justify-content-center align-items-center'>
      <div className='text-center'>
        <img src={image} alt='logo' width='80' />
        <p>
          claribot <small>®</small>{' '}
        </p>
      </div>
    </div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import('../containers/DefaultLayout/DefaultLayout')
);

// Pages
const Login = React.lazy(() => import('../views/Pages/Login/Login'));
const Register = React.lazy(() => import('../views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('../views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('../views/Pages/Page500/Page500'));
const AcountSuccess = React.lazy(() =>
  import('../views/Pages/AccountSuccess/index')
);

const ResetPassword = React.lazy(() => import('../views/Pages/resetPassword'));

const App = () => {
  const [authToken, setAuthToken] = useState();

  console.log('hola');

  const setToken = data => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('role', data.role);
    localStorage.setItem('id', data.id);
    setAuthToken(data);
  };

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
        <Switch>
          <React.Suspense fallback={loading()}>
            <Route
              exact
              path='/register'
              name='Register Page'
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path='/account/reset/password/:token'
              name='Reset Password'
              render={props => <ResetPassword {...props} />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              render={props => <Page500 {...props} />}
            />
            <Route
              exact
              path='/account/validate/success'
              name='Account Success'
              render={props => <AcountSuccess {...props} />}
            />

            <DefaultRoute exact path='/' component={Login} />
            <PrivateRoute path='/admin' component={DefaultLayout} />
          </React.Suspense>
        </Switch>
      </AuthContext.Provider>
    </BrowserRouter>
  );
};

export default App;
